import React from 'react';

const Projects = () => {
  return (
    <div id="projects" className="flex flex-col items-center justify-center min-w-global min-h-screen bg-white">
      <h1 className="text-4xl font-bold text-cyan-500">projects</h1>
      <div className="mt-2 text-lg text-gray-700 w-3/4 text-center">
        <p>
          i have some projects im just too lazy to put them here
        </p>
        <p>
          idk ill put them here soon
        </p>
      </div>

    </div>
  );
};

export default Projects;
